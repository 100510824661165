/* Register */
.warning {
    color: rgb(216, 140, 0);
}
.form {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 600px;
}

.formInput {
    width: 100%;
    height: 50px;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 0;
}
.newTag {
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    z-index: 0;
}
.newTagBtn {
    
}
.formTagsInput {
    width: 100%;
    height: fit-content;
    /* height: 50px; */
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.TagsDropdownWrapper{
    /* border: 1px solid rgb(98, 98, 98); */
    border-radius: 2px;
    /* border-style: solid; */
    /* border-color: black; */
    /* position: absolute; */
    /* display: block; */
    /* top: 0px; */
    width: 206px;
    /* padding: 3px; */
    z-index: 2;
    /* background-color: white; */
    /* margin-top: 20px; */
    /* display: flex; */
    /* flex-direction: column; */
    /* align-items: center; */
    /* justify-content: center; */
}


.formText {
    width: 100%;
    /* height: 50px; */
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.formLabel {
    /* height: 10px; */
    margin: 5px;
}

.formControl {
    width: 200px;
}
.formTextArea {
    width: 600px;
    height: 200px; 
}

.submitBtn {
    margin: 0px;
    height: 30px;
    width: 60px;
    background-color: rgb(48, 79, 165);
    border: none;
    color: white;
    border-radius: 7px;
    font-weight: bold;
}

.formSubmit {
    width: 100%;
    height: 50px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

/* Privacy */

.privacy {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    /* justify-content: center; */
    max-width: 600px;
}

.privacy p {

    text-align: left;
}

.privacyP {
    width: 100%;
    height: 50px;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center
}

/* Home */

.hero {
    margin: 0 auto;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.page {
    margin: 0 auto;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    text-align: left;
    align-items: left;
    justify-content: center;
    max-width: 600px;
    font-size: 18px;
    line-height: 25px;
}

/* Newsletter Form */
.newsletter{
    display: flex;
    flex-direction: column;
    /* text-align: center; */
    align-items: left;
    justify-content: center;
    background-color: rgb(255, 255, 255);
    height: 225px;
    /* max-width: 600px; */
    width: 100%;
    padding: 20px 0;
    margin-bottom: 10px;
}
.formNewsletterLabel{
    height: 20px;
    width: 400px;
    margin-bottom: 10px;
}
.newsletterInput{
    height: 25px;
    width: 300px;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid black;
    font-size: 16px;
    /* text-align: center; */
}

/* .recaptcha{
    display: none;
} */

.formInputDiv{
    display: flex;
    flex-direction: column;
    /* text-align: center; */
    align-items: left;
    justify-content: center;
    background-color: rgb(255, 255, 255);
    margin: 10px 10px;
}
.formInputRecaptcha{
    display: flex;
    flex-direction: column;
    /* text-align: center; */
    align-items: left;
    justify-content: center;
    height: 100px;
    background-color: rgb(255, 255, 255);
    margin: 10px 10px;
}
.formInputSubmit{
    display: flex;
    flex-direction: column;
    /* text-align: center; */
    align-items: left;
    justify-content: center;
    background-color: rgb(255, 255, 255);
    margin: 10px 10px;
}
.formCheckbox{
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}
.formControlCheckInput{
    padding: 5px;
}

.formCheckboxWrapper{
    padding: 5px;
}
.checkBoxLabel{
    padding-left: 5px;
}
