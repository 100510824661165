
/* Footer */
.footer {
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    height: 200px;
    width: 100%;
}

.footer a {
    text-decoration: none;
    color: black;
    width: 100%;
    border: none;
}
.footer a:hover {
    text-decoration: #a8fccb underline;
    width: 100%;
    border: none;
    color: rgb(90, 90, 90);
}

.footLink {
    width: 100%;
    height: 50px;
    display: flex;
    text-align: center;
    background-color: white;
    align-items: center;
    justify-content: center;
}
.copyright{
    width: 100%;
    height: 50px;
    display: flex;
    text-align: center;
    background-color: white;
    align-items: center;
    justify-content: center;
}



@media (max-aspect-ratio: 1) {
    .footer {
        flex-direction: column;
    }
}