/* Navbar */

.logoBoxBtn {
    width: 200px;
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    z-index: 2;
}

.navbar {
    position: sticky;
    margin: 0;
    width: 100%;
    height: 100px;
    background-color: white;
    color: black;
    display: flex;
    flex-direction: row;
}

.right,
.left {
    width: 50%;
    color: black;
}

.right a,
.left a {
    color: black;
    text-decoration: none;
    border: none;
    background-color: rgba(255, 255, 255, 0);
}

.right a:hover,
.left a:hover {
    color: rgb(90, 90, 90);
    text-decoration: none;
    background-color: rgba(255, 255, 255, 0);
    border: none;
}

.left {
    display: flex;
    align-items: center;
    justify-content: start;

}

.right {
    display: flex;
    /* align-items: end; */
    justify-content: end;
}

.link {
    padding: 0 10px;
}

.menuBoxBtn {
    width: 100px;
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    background-color: white;
}

.menuBoxBtn:hover {
    color: rgb(90, 90, 90);
}

.dropDownMenu {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0;
    width: 100%;
    height: fit-content;
    background-color: white;
    position: absolute;
    top: 100px;
    left: 0;
    z-index: 2;
    border-top: 1px solid rgb(190, 190, 190);
    box-sizing: border-box;
}

.menuItem {
    width: 100%;
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    z-index: 3;
    background-color: white;
    border-bottom: 1px solid rgb(190, 190, 190);
    box-sizing: border-box;
}

.menuItem:hover {
    background-color: #a8fccb;
}

.menuCoverEscape {
    display: block;
    background-color: rgba(89, 89, 89, 0.087);
    width: 100vw;
    height: 100vh;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
}

@media screen and (min-aspect-ratio: 1/1) {
    .navMenuText {
        display: flex;
        flex-basis: 1;
    }

    .navSearchText {
        display: flex;
    }

    .navMenuIcon {
        display: none;

    }

    .navSearchIcon {
        display: none;

    }

    .left {
        margin-left: 100px;
    }

    .right {
        margin-right: 82px;
    }
}

@media screen and (max-aspect-ratio: 1/1) {
    .navMenuText {
        display: none;

    }

    .navSearchText {
        display: none;

    }

    .navMenuIcon {
        display: flex;

    }

    .navSearchIcon {
        display: flex;

    }

    .left {
        margin-left: 50px;
    }

    .right {
        margin-right: 13px;
    }
}