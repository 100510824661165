body {
    margin: 0;
    color: black;
    background-color: #ffffff;
    font-family: Georgia, serif;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}



head {
    font-family: Georgia, serif
}

.italic {
    font-style: italic;
}

sup {
    vertical-align: top;
    position: relative;
    top: -0.5em;
}

.sup-comma-break-single {
    left: -0.5em;
    width: .5em;
    margin-right: -0.5em;
}
.sup-comma-break-double {
    left: -0.3em;
    width: .5em;
    margin-right: -0.3em;
}

.superscript {
    font-family: Georgia, serif;
    font-style: italic;
    color: #111;
    border: none;
    font-size: 14px;
}

.in-text-foot {
    border: none;
    text-decoration: #a8fccb underline;
    text-decoration-thickness: 3px;
    padding-right: 0.3em;
}

.foot-number {
    font-family: Georgia, serif;
    font-weight: bold;
    color: #111;
    font-size: 16px;
}

h1 {
    font-family: Georgia, serif;
    color: #111;
    line-height: 1.2em;
    font-size: 32px;
    padding-bottom: 30px;
    padding-top: 0px;
    margin: 0;
}

h2 {
    font-family: Georgia, serif;
    color: #111;
    line-height: 1.2em;
    font-size: 20px;
    padding-bottom: 10px;
    padding-top: 35px;
    margin: 0;
}

.content-div {
    font-size: 19px;
    line-height: 1.5em;
    font-family: Georgia, serif;
    color: #111111;
    padding-bottom: 0px;
    padding-top: 0px;
    margin: 0;
}

.content-div-footnotes {
    font-size: 17px;
    line-height: 1.5em;
    font-family: Georgia, serif;
    color: #111111;
    padding-bottom: 0px;
    padding-top: 0px;
    margin-left: 30px;
}


a {
    font-family: Georgia, serif;
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    text-decoration: none;
    border-bottom: 2px solid #b9f9d4;
    color: #111111;
}

a:hover {
    color: #111111;
    background: #a8fccb;
    border-bottom: 2px solid #a8fccb;
}

button:hover {
    color: #320101;
    border: 0;
    background: #FCDD8E
}

.btn:hover {
    color: #320101;
    border: 0;
    background: #FCDD8E
}

.button:hover {
    color: #320101;
    border: 0;
    background: #FCDD8E;
}

em,
strong {
    font-family: Georgia, serif;
    color: inherit;
    font-family: inherit;
    font-size: inherit
}

#preview-text {
    font-size: 18px;
    line-height: 1.5em;
    font-family: Georgia, serif;
    color: #111111;
    display: none;
}

#preview-text-span {
    display: none;
    max-height: 0;
    overflow: hidden;
}

.message-content {
    font-size: 18px;
    line-height: 1.5em;
    font-family: Georgia, serif;
    color: #111111;
    max-width: 600px;
    margin: 30px auto;
}

.header {
    font-size: 18px;
    line-height: 1.5em;
    font-family: Georgia, serif;
    color: #111111;
    padding-bottom: 10px;
    background: #a8fccb;
    padding: 32px 20px;
    margin-bottom: 40px;
    text-align: center;
}

.header-title {
    line-height: 1.5em;
    font-family: Georgia, serif;
    color: #111111;
    font-size: 24px;
    font-style: italic;
    margin: 20px auto 0;
    max-width: 300px;
    padding-bottom: 10px;
}

.header-subtitle {
    line-height: 1.5em;
    font-family: Georgia, serif;
    color: #111111;
    font-size: 19px;
    font-style: italic;
    margin: 20px auto 0;
    max-width: 300px;
    padding-bottom: 10px;
}

.sent-by {
    font-family: Georgia, serif;
    font-size: 16px;
    color: #999999;
    font-style: italic;
}

.author {
    font-family: Georgia, serif;
    text-decoration: none;
    border-bottom: 2px solid #a8fccb;
    color: #111111;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.1em;
    text-transform: uppercase;
}

.separator {
    font-family: Georgia, serif;
    color: #999;
    font-size: 12px;
    margin: 0 10px;
}

.date {
    font-family: Georgia, serif;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.1em;
    text-transform: uppercase;
}

.email-image {
    font-family: Georgia, serif;
    text-align: center;
    table-layout: fixed;
    float: none;
}

.email-hr {
    border: none;
    border-top: 3px double #111111;
    color: #111111;
    overflow: visible;
    text-align: center;
    height: 5px;
}

.email-hr::after {
    background: #fff;
    content: '§';
    padding: 0 4px;
    position: relative;
    top: -13px;
}

.small-hr {
    font-family: Georgia, serif;
    color: #EDEADE;
    background: #EDEADE;
    border: 0;
    height: 1px;
    text-align: left;
    margin-left: 0;
    margin-top: 40px;
    margin-bottom: 40px;
    width: 100px;
}

/* .footer {
    font-size: 18px;
    line-height: 1.5em;
    font-family: Georgia, serif;
    padding-bottom: 10px;
    margin-top: 40px;
    padding: 20px 40px;
    color: #000000;
    line-height: 1.5;
    text-align: left;
    background: #fff;
    margin-top: 0;
} */

/* .footer a {
    color: #000000;
    line-height: 1.5;
}

.footer-address {
    margin-top: 20px;
    font-size: 16px;
    line-height: 0.5em;
} */

